import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Image from "mui-image";

import styled from "styled-components";
import { blue, cyan, green, grey, orange, red } from "@mui/material/colors";
import { memo } from "react";
import { useProductContext } from "../../../contexts/ProductContext";
import {
  Close,
  Edit,
  Favorite,
  FavoriteBorderOutlined,
  Person,
  Person2,
  Sell,
  Store,
} from "@mui/icons-material";
import { DialogConfirmation } from "../../DialogConfirmation";
import { useState } from "react";

import { Label } from "@mui/icons-material";
import { useFavorites } from "../../../hooks/useFavorites";
import { ActionButton } from "./ActionButton";
import { currencyFormatter } from "../../../utils/currencyFormatter";
import { Grade } from "../Grade";
import { useSelectedProductsContext } from "../../../contexts/SelectedProductsContext";

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;

  object-fit: contain;
  user-select: none;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: #fee9be;
  }
`;

export const ProductCardDefault = ({
  children,
  variant,
  product,
  activeHover,
  grade = [],
  actions,
  favorite,
  finalPrice,
  disableApplyGradeButton,
  totalQuant,
  disableImageCache = false,
  disableLight = false,
  customRemoveRender,
}) => {
  const { isFavorite, toggleFavorite } = useFavorites(product, favorite);
  const { isSelected, toggleSelection } = useSelectedProductsContext();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isBorderActive = () => {
    if (!isSelected(product.id) || disableLight || variant === "extended") {
      return "transparent";
    }

    return isSelected(product.id) && "success.light";
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          minHeight: 130,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          borderColor: isBorderActive(),
          borderStyle: "solid",
          zIndex: 10,
          "&:hover": {
            backgroundColor: "#FCFCFC",
          },
          cursor: activeHover && "pointer",
        }}
        onClick={() => {
          toggleSelection(product);
        }}
      >
        {/* Container 1 */}
        <Box
          sx={{
            flex: variant === "extended" ? 3 : "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Tooltip title="Clique para abrir a imagem" placement="top" arrow>
            <Box
              sx={{
                width: isMobile ? "100%" : "100px",
                height: isMobile ? "150px" : "100%",
                backgroundColor: "#f8f8f8",
                paddingY: isMobile && 1,
                overflow: "hidden",
                marginBottom: isMobile ? "5px" : "0",
              }}
            >
              {disableImageCache && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenDialog();
                  }}
                >
                  <img
                    src={`https://democrata-public-files.s3.amazonaws.com/thumbs/${
                      product.referencia
                    }.png?${new Date().getTime()}`}
                    crossOrigin="anonymous"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: 60,
                    }}
                  />
                </Box>
              )}
              {!disableImageCache && (
                <StyledImage
                  src={`https://democrata-public-files.s3.amazonaws.com/${product.referencia}.png`}
                  alt={product.titulo}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenDialog();
                  }}
                  bgColor="#faf5f5"
                  onError={(e) =>
                    (e.target.src = "../assets/image-error-placeholder.png")
                  }
                  fit="contain"
                />
              )}
            </Box>
          </Tooltip>
          <Box
            width="100%"
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            my={isMobile && 2}
            ml={!isMobile && 0.4}
            px={1}
          >
            <Box
              display="flex"
              flexDirection={
                !isMobile && variant === "extended" ? "column" : "row"
              }
              alignItems={variant === "extended" ? "flex-start" : "center"}
              justifyContent={
                isMobile
                  ? "space-between"
                  : variant !== "extended"
                  ? "space-between"
                  : undefined
              }
              mb={1}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: isMobile ? "flex-start" : "center",
                  width: "100%",
                  height: "100%",

                  justifyContent: "space-between",
                }}
              >
                <Tooltip
                  title={
                    <Box>
                      {product.descricaoRegra ||
                        `${product.conceito} ${product.linha} ${product.cor}`}
                    </Box>
                  }
                  arrow
                >
                  <Typography
                    variant={"h6"}
                    fontSize={isSmallScreen && 15}
                    maxWidth={
                      !disableImageCache && isMobile
                        ? 200
                        : variant !== "extended"
                        ? 230
                        : "100%"
                    }
                    sx={{
                      mr: !isMobile && 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: !isMobile && "nowrap",
                    }}
                  >
                    {product.linha} {product.cor}
                  </Typography>
                </Tooltip>

                <Typography
                  variant="body1"
                  color="textSecondary"
                  fontSize={isSmallScreen && 15}
                  fontWeight={"bold"}
                >
                  {product.referencia}
                </Typography>

                {customRemoveRender && customRemoveRender}
              </Box>
            </Box>

            {isSmallScreen && (
              <Typography>
                {(product.semana &&
                  new Date(product.semana).toLocaleDateString("pt-BR", {
                    timeZone: "UTC",
                  })) ||
                  product.entrega}{" "}
              </Typography>
            )}

            <Box
              mb={1}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box>
                {variant !== "extended" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {!finalPrice && variant !== "extended" && (
                      <Tooltip title="Preço de Venda" arrow>
                        <Box
                          sx={{
                            backgroundColor: blue[50],
                            borderRadius: "10px",
                            border: `1px solid ${blue[100]}`,
                            px: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Person2
                            sx={{ mr: 1, color: blue[800], fontSize: "20px" }}
                          />
                          <Typography
                            color={blue[800]}
                            fontSize={isMobile ? 16 : 16}
                            textAlign="center"
                          >
                            {currencyFormatter(
                              product.precoRepresentante?.preco ||
                                product.precoVendedor ||
                                0
                            )}
                          </Typography>
                        </Box>
                      </Tooltip>
                    )}

                    {finalPrice && variant !== "extended" && (
                      <>
                        <Typography
                          fontWeight={600}
                          color={grey[800]}
                          fontSize={isMobile ? 16 : 16}
                          textAlign="center"
                        >
                          {currencyFormatter(product.unitario || 0)}
                        </Typography>
                      </>
                    )}

                    {product.precoConsumidor && (
                      <Tooltip title="Preço Recomendado" arrow>
                        <Box
                          sx={{
                            backgroundColor: green[50],
                            borderRadius: "10px",
                            border: `1px solid ${green[100]}`,
                            px: 1,
                            display: "flex",
                            alignItems: "center",
                            ml: 1,
                          }}
                        >
                          <Sell
                            sx={{ mr: 1, color: cyan[900], fontSize: "20px" }}
                          />
                          <Typography
                            variant="body2"
                            fontSize={isMobile ? 16 : 16}
                            textAlign="center"
                            color={cyan[900]}
                          >
                            {currencyFormatter(
                              product.precoConsumidor?.preco ||
                                product.precoConsumidor ||
                                0
                            )}
                          </Typography>
                        </Box>
                      </Tooltip>
                    )}

                    {/* {select && hover && hover.isHovered && !isChecked && (
                    <IconButton>
                      <RadioButtonUncheckedIcon
                        sx={{ width: 24, height: 24, color: grey[600] }}
                      />
                    </IconButton>
                  )} */}
                  </Box>
                )}
              </Box>

              {!isSmallScreen && variant !== "extended" && (
                <Typography>
                  {(product.semana &&
                    new Date(product.semana).toLocaleDateString("pt-BR", {
                      timeZone: "UTC",
                    })) ||
                    product.entrega}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Grid
                container
                sx={{
                  minHeight: 64,
                  alignItems: "flex-end",
                }}
              >
                {grade.map((item) => (
                  <Grade
                    key={item.nro}
                    nro={item.nro}
                    estoque={item.estoque}
                    alert={item.alertStock}
                  />
                ))}

                <Box
                  sx={{
                    position: "sticky",
                  }}
                >
                  {grade.length > 0 && (
                    <ActionButton
                      action={actions?.remove}
                      title="Remover grade"
                      color={red[700]}
                      IconComponent={Close}
                    />
                  )}

                  {grade.length > 0 && (
                    <ActionButton
                      action={actions?.edit}
                      title="Editar grade"
                      color={orange[700]}
                      IconComponent={Edit}
                    />
                  )}
                </Box>

                {!disableApplyGradeButton && grade && grade.length === 0 && (
                  <Button
                    variant="text"
                    className="text-muted"
                    size="small"
                    disabled={disableApplyGradeButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.handleAddGrade(product);
                    }}
                  >
                    Clique aqui para aplicar grade
                  </Button>
                )}
              </Grid>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="overline" mx={1}>
                Total: {totalQuant || product.quantidade}
              </Typography>

              <Box>
                {favorite ? (
                  <>
                    {isSelected(product.id) && (
                      <IconButton
                        sx={{
                          transition: "transform 0.3s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                          position: "sticky",
                        }}
                      >
                        <CheckCircleIcon
                          sx={{
                            width: 24,
                            height: 24,
                            color: "success.light",
                          }}
                        />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleFavorite();
                      }}
                    >
                      {isFavorite ? (
                        <Favorite sx={{ color: red[800] }} />
                      ) : (
                        <FavoriteBorderOutlined
                          color="error"
                          sx={{ color: grey[800] }}
                        />
                      )}
                    </IconButton>
                  </>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Container 2 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          {variant === "extended" && children}
        </Box>
      </Card>

      <DialogConfirmation
        open={isDialogOpen}
        onClose={handleCloseDialog}
        closeButton
        imageDialog
      >
        <Box
          display="flex"
          justifyContent="center"
          flexDirection={"column"}
          width="100%"
          minHeight={200}
        >
          <Typography variant="h5" color={grey[800]} textAlign="center">
            {product.descricaoRegra ||
              `${product.conceito} ${product.linha} ${product.cor}`}
          </Typography>

          <Typography color={grey[800]} textAlign="center" mt={2}>
            <Label sx={{ color: "orange" }} fontSize="12" />{" "}
            {product.referencia}
          </Typography>

          <Paper sx={{ padding: 4, mt: 4, height: 400 }}>
            <Image
              src={`https://democrata-public-files.s3.amazonaws.com/${product.referencia}.png`}
              alt={product.titulo}
              loading="lazy"
              fit="contain"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "path/to/fallback-image.png";
              }}
            />
          </Paper>
        </Box>
      </DialogConfirmation>
    </>
  );
};
